import {useMemo} from 'react';

import {getCMSClient} from '@tetra-next/graphql-client';

import {useSubdomain} from './useSubdomain';

const commit = process.env['NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA'];
const isProduction = process.env['NEXT_PUBLIC_VERCEL_ENV'] === 'production';

export const useCMSClient = () => {
  const subdomain = useSubdomain();

  return useMemo(() => {
    return getCMSClient({
      subdomain,
      version: isProduction ? commit : undefined,
    });
  }, [subdomain]);
};
